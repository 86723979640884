<template>
  <div class="text-[16px] py-8 px-4 text-center border-2 border-red-200">
    目標金額に到達したため、募集は終了いたしました！🎉<br />
    たくさんのご参加、ありがとうございました！<br />
    <br />
    詳細は、公演が近づきましたら X にてポストいたします！<br />
  </div>
</template>

<script setup lang="ts">
</script>
